module.exports = [{
      plugin: require('/zeit/1049f27b/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Bovieran – Bo blant palmer og oliventrær i Norge","short_name":"Bovieran","start_url":"/","background_color":"#55504d","theme_color":"#55504d","display":"minimal-ui","icon":"src/images/favicon.png"},
    },{
      plugin: require('/zeit/1049f27b/node_modules/gatsby-plugin-jss/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/zeit/1049f27b/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
